.sprite {
  width: 1.5rem;
  height: 1.5rem;
  fill: $color-black;
  display: inline-block;
  transition: all 200ms ease-in-out;

  &--responsive {
    width: 100%;
    height: auto;
  }

  &--toggle {
    cursor: pointer;
  }
}