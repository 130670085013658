.footer {
  text-align: center;
  color: $color-dark-green;

  h1, h2, h3, h4, h5 {
    color: $color-black;
    letter-spacing: .24em;
  }

  &__columns {
    display: grid;
    grid-row-gap: 3rem;
    grid-column-gap: 2rem;
    grid-template-columns: repeat(1, 1fr);
  }

  &__column {
    &:first-child .sprite {
      width: 70%;
    }

    &:first-child .sprite {
      margin-bottom: 1.5rem;
    }

    &:first-child > .sprite:last-child {
      margin-bottom: 0;
    }
  }

  &__links {
    margin-top: 1.5rem;

    :not(:last-child) {
      margin-right: .5rem;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  &__bar {
    text-align: center;
    padding: 1rem 2rem;
    color: $color-white;
    background-vegetables-green();
  }
}

@media all and (min-width: 500px) {
  .footer {
    &__column {
      &:first-child .sprite {
        width: 50%;
      }
    }
  }
}

body.home .footer {
  background: $color-light-gray;
  border-top: .5rem solid $color-dark-green;
}

@media all and (min-width: $breakpoint-m) {
  .footer {
    &__columns {
      grid-template-columns: repeat(2, 1fr);
    }

    &__column {
      &:first-child {
        display: grid;
        grid-column: span 2;
        grid-column-gap: 2rem;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        justify-items: center;

        .sprite {
          margin-bottom: 0;
        }
      }

      &:first-child .sprite {
        width: 70%;
      }
    }
  }
}

@media all and (min-width: $breakpoint-l) {
  .footer {
    text-align: left;

    &__columns {
      grid-column-gap: 4rem;
      grid-template-columns: repeat(3, 1fr);
    }

    &__column {
      &:first-child {
        display: block;
        grid-column: span 1;

        .sprite {
          margin-bottom: 1.5rem;
        }

        > .sprite:last-child {
          margin-bottom: 0;
        }
      }

      &:first-child .sprite {
        width: 85%;
        max-width: 16rem;
      }
    }
  }
}

@media all and (min-width: $breakpoint-xl) {
  .footer {
    &__title {
      margin-bottom: 2rem;
    }

    &__bar {
      margin-top: -2rem;
    }
  }
}
