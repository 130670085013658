html {
  font-size: 100%;
}

body {
  color: $color-black;
  font-family: $font-sourcecode;
}

a {
  color: $color-orange;
  transition: color 200ms ease-in-out;
}

b, strong {
  font-weight: 600;
}

i, em {
  font-style: italic;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75rem;
  margin-bottom: 2rem;
}

sup {
//@extends p;
  font-size: 80%;
  position: relative;
  top: -.375rem;
//vertical-align: super;
}

table {
  margin: 0 0;
  padding: 0 0;
  display: table;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $color-darkbrown;
  border-collapse: collapse;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  color: $color-black;
  text-transform: uppercase;
  font-family: $font-montserrat;
}

h1, .h1 {
  font-weight: 600;
  font-size: 2.75rem;
  margin-bottom: 1rem;
  line-height: 3.4375rem;
}

h2, .h2 {
  font-weight: 700;
  font-size: 1.85rem;
  margin-bottom: .5rem;
  line-height: 2.5rem;
}

h3, .h3 {
  font-weight: 700;
  font-size: 1.25rem;
  margin-bottom: .5rem;
  line-height: 1.5625rem;
}

h4, .h4 {
  font-weight: 600;
  font-size: 1.125rem;
  margin-bottom: .5rem;
  line-height: 1.5rem;
}

h5, .h5 {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  margin-bottom: .25rem;
}

.page__inner, .content__inner {
  ol, ul {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 2rem;
    padding-left: 1.75rem;
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }

  li:not(:last-child) {
    margin-bottom: 1rem;
  }
}

@media all and (min-width: $breakpoint-m) {
  .alignleft {
    float: left;
    margin-right: 1.875rem;
    margin-bottom: 1.875rem;
  }

  .alignright {
    float: right;
    margin-left: 1.875rem;
    margin-bottom: 1.875rem;
  }
}

@media all and (min-width: $breakpoint-xl) {
  h1, .h1 {
    font-size: 4rem;
    line-height: 5rem;
    margin-bottom: 1rem;
  }

  h2, .h2 {
    font-size: 3rem;
    line-height: 3.5rem;
    margin-bottom: 2rem;
  }

  h3, .h3 {
    font-size: 1.5rem;
    line-height: 1.875rem;
  }

  h4, .h4 {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }

  h5, .h5 {
    font-size: 1.25rem;
    line-height: 1.625rem;
  }

  .page__inner, .content__inner {
    ol, ul {
      padding-left: 2rem;
    }
  }
}

.swal2-icon.swal2-info {
  color: $color-dark-green !important;
  border-color: $color-dark-green !important;
}