.navigation {
  background: $color-white;

  &__inner {
    display: flex;
    position: relative;
    align-items: center;
    flex-flow: row wrap;
  }

  &__logotype {
    padding: 1rem 0 1rem .75rem;
    flex: 0 0 calc(100% - 3rem);

    img {
      width: 80%;
      height: auto;
      display: block;
    }
  }

  &__social {
    width: 100%;
    display: flex;
    padding: 1rem 0;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid $color-dark-green - 20%;

    background: $color-dark-green;

    .button {
      margin: 0 .5rem;
    }

    .button:first-child {
      margin-right: 0;
      color: $color-white;
      background: $color-dark-green;
      border-color: $color-dark-green;
    }
  }

  &__toggle {
    border: none;
    outline: none;
    display: block;
    cursor: pointer;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    background: none;

    &--main {
      width: 2.5rem;
      height: 2.5rem;
      flex: 0 0 2.5rem;
      margin-right: .5rem;

      position: relative;

      span {
        width: 55%;
        height: 2px;
        display: block;
        border-radius: 4px;
        background: $color-dark-green;
        position: absolute;
        left: 22.5%;

        transition: all 200ms ease-in-out;

        &:nth-child(1) {
          top: 14px;
        }

        &:nth-child(2) {
          top: 19px;
        }

        &:nth-child(3) {
          top: 24px;
        }
      }
    }

    &--main&--toggled span {
      &:nth-child(1) {
        top: 19px;
        width: 45%;
        left: 27.5%;
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        top: 19px;
        width: 45%;
        left: 27.5%;
        transform: rotate(-45deg);
      }
    }

    &--sub {
      display: none;
      width: 2.5rem;
      height: 2.5rem;
      margin: 0 0 0 0;
      padding: 0 0 0 0;
      appearance: none;

      text-align: center;

      position: absolute;
      top: 5px; right: 0;
      transition: transform 200ms ease-in-out;

      .sprite {
        width: .5rem;
        height: .5rem;
        position: absolute;
        top: 50%; left: 50%;
        fill: $color-dark-green;
        transform: translate(-50%, -50%);
      }
    }

    &--sub&--toggled {
      transform: rotate(180deg);
    }
  }

  &__menu {
    opacity: 0;
    display: none;
    flex: 0 0 100%;
    font-weight: 700;
    letter-spacing: .24em;
    text-transform: uppercase;
    background: $color-light-gray;
    transition: opacity 200ms ease-in-out;

    .menu {
      .menu-item {
        display: block;
        position: relative;

        a {
          display: block;
          text-decoration: none;
          color: $color-dark-green;
          padding: $margin-horizontal-small;
        }

        &:not(:last-child) {
          border-bottom: 2px solid $color-white;
        }

        &.current-menu-item {
          a {
            color: $color-orange;
          }
        }

        &-has-children {
          .sub-menu {
            opacity: 0;
            display: none;
            font-weight: 500;
            transition: opacity 200ms ease-in-out;

            .menu-item {
              border-bottom: 0;

              a {
                padding: .75rem ($margin-horizontal-small * 2);
              }

              &:last-child {
                padding-bottom: $margin-horizontal-small;
              }
            }
          }

          > .navigation__toggle--sub {
            display: block;
          }

          &:hover,
          &.current-menu-item {
            .sub-menu {
              .menu-item {
                a {
                  color: $color-dark-green;
                }
              }
            }
          }
        }

        &.disabled,
        &.disabled:hover {
          a {
            opacity: .3;
            pointer-events: none;
            color: $color-dark-gray;
          }
        }
      }
    }
  }

  & &__toggle--main&__toggle--toggled ~ &__menu {
    opacity: 1;
  }

  & &__toggle--sub&__toggle--toggled ~ .sub-menu {
    opacity: 1;
  }
}

@media all and (min-width: 640px) {
  .navigation {
    &__logotype {
      img {
        width: 25rem;
      }
    }
  }
}

@media all and (min-width: $breakpoint-m) {
  .navigation {
    position: relative;
    z-index: 10;

    &__logotype {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      padding-left: $margin-horizontal-medium - 0.5rem;
      flex: 0 0 calc(100% - 4rem);

      img {
        width: 20rem;
      }
    }

    &__toggle {
      &--main {
        margin-right: $margin-horizontal-medium - 0.5rem;
      }

      &--sub {
        width: 2rem;
      }
    }

    &__social {
      width: auto;
      display: flex;
      position: absolute;
      top: 0; right: 6rem;
      padding: .5rem 1rem;
      border-bottom: none;
      background: $color-dark-green;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &__menu {
      .menu {
        text-align: center;

        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;

        .menu-item {
          display: block;
          margin: 0 .5rem;
          position: relative;

          a {
            padding-right: 0;
          }

          &-has-children {
            margin-right: 0;
            padding-right: 2rem;
          }

          &:not(:last-child) {
            border-bottom: 0;
          }

          .sub-menu {
            position: absolute;
            top: 100%; left: .5rem;

            .menu-item {
              display: block;
              text-align: left;
              font-size: .875rem;
              background: rgba($color-white, .75);

              a {
                white-space: nowrap;
                padding: .625rem 1.25rem;
              }

              &:first-child {
                margin-top: 1rem;
                padding-top: .625rem;
                border-top-left-radius: 2px;
                border-top-right-radius: 2px;
              }

              &:last-child {
                padding-bottom: .625rem;
                border-bottom-left-radius: 2px;
                border-bottom-right-radius: 2px;
              }
            }

            &:after {
              width: 0;
              height: 0;
              content: '';
              display: block;
              border-left: 7px solid transparent;
              border-right: 7px solid transparent;
              border-bottom: 7px solid rgba($color-white, .75);

              position: absolute;
              top: .5625rem; left: 1rem;
            }
          }
        }
      }
    }
  }
}

@media all and (min-width: 1025px) {
  .navigation {
    position: relative;
    z-index: 10;

    &__inner {
      justify-content: space-between;
      padding: 0 $margin-horizontal-large;
    }

    &__logotype {
      padding: 3.5rem 0;
      flex: 0 0 auto;

      img {
        width: 14.375rem;
      }
    }

    &__toggle--main {
      display: none;
    }

    &__social {
      right: 4rem;
    }

    &__menu {
      opacity: 1;
      display: block;
      flex: 0 0 auto;
      background: none;
      font-size: .875rem;
      visibility: visible;
      margin-right: -1rem;

      .menu {
        .menu-item {
          margin: 0 0;
          background: none;

          a {
            padding: .5rem 1rem;
            color: $color-dark-green;
          }

          .sub-menu {
            opacity: 0;
            padding-top: 3.5rem;
            display: block;
            visibility: hidden;

            &:after {
              top: 4.0625rem;
            }

            .menu-item {
              text-shadow: none;

              a {
                color: $color-darkbrown;
              }

              &.current-menu-item {
                a {
                  color: $color-orange;
                }
              }
            }
          }

          &-has-children {
            padding: inherit;

            > .navigation__toggle--sub {
              display: none;
            }
          }

          &:hover {
            a {
              color: $color-orange;
            }


            .sub-menu {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }

    &--collapsed {
      * {
        text-shadow: none;
      }

      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

@media all and (min-width: $breakpoint-xl) {
  .navigation {
    &__logotype {
      img {
        width: 21rem;
      }
    }

    &__menu {
      .menu {
        .menu-item {
          .sub-menu {
            padding-top: 4rem;

            &:after {
              top: 4.5624rem;
            }
          }
        }
      }
    }
  }
}