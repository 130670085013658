.container {
  &__inner {
    margin: 0 auto;
    max-width: 77.375rem + ($margin-horizontal-large * 2);

    &:not(&--wide) {
      padding-left: $margin-horizontal-small;
      padding-right: $margin-horizontal-small;
    }
  }
}

@media all and (min-width: 640px) {
  .container {
    &__inner {
      &:not(&--wide) {
        padding-left: $margin-horizontal-medium;
        padding-right: $margin-horizontal-medium;
      }

      &--slim {
        max-width: 45rem;
      }
    }
  }
}

@media all and (min-width: $breakpoint-l) {
  .container {
    &__inner {
      &--page {
        max-width: 60rem;
      }

      &--centered {
        text-align: center;
      }
    }
  }
}

@media all and (min-width: $breakpoint-xl) {
  .container {
    &__inner {
      &:not(&--wide) {
        padding-left: $margin-horizontal-large;
        padding-right: $margin-horizontal-large;
      }

      &--slim {
        max-width: 60rem;
      }

      &--page {
        max-width: 70rem;
      }
    }
  }
}