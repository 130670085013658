.gform_wrapper {
  width: 100%;

  .almbay_progress {
    width: 100%;
    display: flex;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    max-width: 37.5rem;
    flex-flow: row wrap;
    margin-bottom: 1rem;
    align-items: flex-start;
    justify-content: space-between;

    &:before {
      content: '';
      width: 100%;
      display: block;
      height: .375rem;
      background: $color-orange;

      position: absolute;
      top: 1.3125rem; left: 0;
    }

    &_step {
      z-index: 1;
      flex: 0 0 100%;
      text-align: center;
      position: relative;

      span {
        width: 2.8125rem;
        height: 2.8125rem;
        color: $color-white;
        border-radius: 100%;
        display: inline-block;
        margin-bottom: .75rem;
        background: $color-dark-gray;

        text-align: center;
        line-height: 2.8125rem;
      }

      label {
        display: block;
        font-weight: 500;
        font-size: .875rem;
        word-break: break-all;
        letter-spacing: .24em;
        text-transform: uppercase;
        font-family: $font-montserrat;

        br {
          display: none;
        }
      }
    }
  }

  .gform_page:first-child {
    .almbay_progress {
      &_step:first-child {
        span {
          background: $color-orange;
        }
      }

      &_step:last-child {
        display: none;
      }
    }
  }

  .gform_page:last-child {
    .almbay_progress {
      &_step:first-child {
        display: none;
      }

      &_step:last-child {
        span {
          background: $color-orange;
        }
      }
    }
  }

  .gf_progressbar {
    border-radius: .25rem;
    background: $color-dark-gray;

    &_percentage {
      padding: .25rem;
      font-weight: 600;
      text-align: center;

      span {
        display: block;
        padding: .5rem;
        border-radius: .125rem;
        background: $color-orange;
      }
    }

    &_wrapper {
      display: none;
      margin-bottom: 1.5rem;
    }

    &_title {
      text-align: center;
      margin-bottom: 1.5rem;
    }
  }

  .validation_error {
    padding: 1rem;
    font-size: .875rem;
    line-height: 1.25rem;
    border-radius: .25rem;
    background: $color-red;
    margin-bottom: 1.5rem;
  }

  .gform_fields {
    margin: 0 0;
    padding: 0 0;
    list-style-type: none;

    display: grid;
    grid-row-gap: 1.5rem;
    grid-column-gap: 1.5rem;
    grid-template-columns: repeat(1, 1fr);
  }

  .gfield {
    margin: 0 0;
    padding: 0 0;

    &_label {
      display: none;
      font-weight: 600;
      margin-bottom: .5rem;
      text-transform: uppercase;
      font-family: $font-montserrat;
    }

    &_checkbox {
      margin: 0 0 0 0;
      padding: 0 0 0 0;
      list-style-type: none;

      li:not(:last-child) {
        margin-bottom: 1rem;
      }

      input {
        display: none;
      }

      label {
        cursor: pointer;
        position: relative;
        align-items: center;
        display: inline-flex;

        &:before {
          content: '';
          display: block;
          cursor: pointer;
          width: 1.84375rem;
          height: 1.6875rem;
          flex: 0 0 1.84375rem;
          margin-right: .5rem;
          background: url('../img/checkbox.png') no-repeat bottom;
          background-size: 1.84375rem 3.375rem;
        }
      }

      input:checked ~ label {
        &:before {
          background-position: top;
        }
      }
    }

    .ginput_container {
      position: relative;

      &:after {
        content: '';
        display: block;
        background-color: $color-dark-gray;

        mask-size: cover;
        pointer-events: none;
        transition: opacity 300ms ease-in-out;

        z-index: 3;
        position: absolute;
      }
    }

    .ginput_container_select:after {
      width: .625rem;
      height: .3125rem;
      mask: url('../img/sprites/caret.svg') no-repeat center;

      top: 50%; right: .75rem;
      transform: translateY(-50%);
    }

    .ginput_container_fileupload {
      margin-bottom: 1rem;
      position: relative;
      overflow: hidden;

      div:first-child {
        float: left;
        padding: 2px 2px;
        border-radius: .5625rem;
        background: $color-white;
      }

      .gform_drop_area {
        display: flex;
        align-items: center;
      }

      .gform_drop_instructions {
        color: $color-orange;

        @media all and (min-width: $breakpoint-s) {
          padding: 0 1rem;
        }

        @media all and (min-width: $breakpoint-m) {
          padding: 0 2rem;
        }
      }

      .gform_button_select_files {
        color: $color-white;
        background: $color-dark-green;
        border-color: $color-dark-green;
      }

      .screen-reader-text {
        display: none;
      }

      & + div {
        overflow: hidden;
        color: $color-orange;
        border-radius: .375rem;
        background: $color-white;

        .ginput_preview {
          display: flex;
          align-items: center;
          padding: 1rem 1rem;

          img {
            margin-right: 1rem;
          }

          &:nth-child(even) {
            //background: #f9f9f9;
          }

          &:not(:last-child) {
            border-bottom: 1px solid #ebebeb;
          }
        }
      }
    }

    .ginput_container_password {
      display: grid;
      grid-row-gap: 1.5rem;
      grid-column-gap: 1.5rem;
      grid-template-columns: repeat(1, 1fr);

      .ginput_left,
      .ginput_right {
        label {
          display: none;
        }
      }

      .gf_clear_complex {
        display: none;
      }
    }

    select,
    textarea,
    input[type="text"],
    input[type="email"],
    input[type="number"],
    input[type="password"] {
      margin 0 0;
      width: 100%;
      border: none;
      outline: none;
      appearance: none;
      border-radius: .25rem;

      padding: .75rem .75rem;
      background: $color-white;

      font-weight: 600;
      font-size: .875rem;
      color: $color-dark-gray;
      font-family: $font-sourcecode;

      &::placeholder {
        color: $color-orange;
        text-transform: uppercase;
      }
    }

    &_error {
      select,
      textarea,
      input[type="text"],
      input[type="email"],
      input[type="number"] {
        color: $color-red;

        &::placeholder {
          color: $color-red;
        }
      }

      .ginput_container_fileupload .gform_drop_instructions {
        color: red;
      }
    }

    .instruction, &_description {
      display: none;
    }

    &--upload .gfield_description:not(.validation_message) {
      display: block;
      margin-bottom: 1rem;
    }

    &--eula {
      ul, ol {
        margin: 0 0;
        padding: 0 0;
        list-style-type: none;
      }

      label {
        cursor: pointer;
      }

      .validation_message {
        padding: 1rem;
        display: block;
        margin-top: 1rem;
        font-size: .875rem;
        line-height: 1.25rem;
        border-radius: .25rem;
        background: $color-red;
        margin-bottom: 1.5rem;
      }
    }

    &--confirm {
      padding-top: 1rem;
    }

    &.gform_hidden {
      display: none;
    }

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  .gform_footer {
    margin-top: 2.5rem;

    img {
      display: none;
    }
  }

  .gform_page_footer {
    display: flex;
    margin-top: 2.5rem;
    flex-flow: row wrap;

    align-items: center;
    justify-content: space-between;

    img {
      display: none;
    }

    .button {
      width: 100%;
      display: block;

      &.gform_previous_button {
        margin-bottom: 1rem;
      }
    }
  }
}

.gform_confirmation_wrapper {
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
  border-radius: .25rem;
  background: $color-white;
  color: $color-dark-green;
  margin-bottom: 1.5rem;
}

@media all and (min-width: $breakpoint-m) {
  .gform_wrapper {
    .almbay_progress {
      margin-bottom: 1.5rem;

      &:before {
        width: 60%;
        left: 20%;
      }

      &_step {
        flex: 0 0 40%;
      }
    }

    .gform_page:first-child {
      .almbay_progress {
        &_step:last-child {
          display: block;
        }
      }
    }

    .gform_page:last-child {
      .almbay_progress {
        &_step:first-child {
          display: block;
        }
      }
    }

    .gform_fields {
      grid-template-columns: repeat(2, 1fr);
    }

    .gfield {
      &--half {
        grid-column: span 1;
      }

      &--full {
        grid-column: span 2;
      }

      .ginput_container_password {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .gform_page_footer {
      display: flex;
      margin-top: 2.5rem;

      .button {
        width: auto;
        display: block;

        &.gform_previous_button {
          margin-bottom: 0;
        }
      }
    }
  }
}

.limit-message {
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
  border-radius: .25rem;
  background: $color-white;
  color: $color-dark-green;
}