.threecol {
  padding-top: 2rem;

  &__columns {
    width: 100%;
    display: flex;
    overflow: hidden;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: center;
    margin-bottom: -4rem;
  }


  &__column {
    flex: 0 0 100%;
    overflow: hidden;
    text-align: center;
    margin-bottom: 4rem;
  }

  &__circle {
    width: 100%;
    padding: 2.5rem;
    overflow: hidden;
    max-width: 18rem;
    border-radius: 100%;
    margin: 0 auto 1.5rem;
    background: $color-white;
    border: 5px solid $color-dark-green;
  }

  &__title {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }

  .button {
    margin-top: .5rem;
  }

  &__inner {
    margin: 0 auto;

    :last-child {
      margin-bottom: 0;
    }
  }

}

@media all and (min-width: $breakpoint-s) {
  .threecol {
    &__inner {
      width: 18rem;
    }
  }
}

@media all and (min-width: $breakpoint-m) {
  .threecol {
    padding-top: 2rem;

    &__column {
      flex-basis: 50%;
    }

    &__columns--single &__column {
      flex-basis: 100%;
    }

    &__columns--single &__inner {
      width: 40rem;

    }
  }
}

@media all and (min-width: $breakpoint-l) {
  .threecol {
    &__columns:not(&__columns--single) {
      justify-content: space-between;
    }

    &__column {
      flex-basis: 33%;
    }

    &__inner {
      width: 18rem;
    }
  }
}