.content {
  &__header {
    margin-bottom: 2rem;

    h2 {
      margin-bottom: 0;
    }
  }

  &__video {
    margin-bottom: 2rem;

    iframe {
      overflow: hidden;
      border-radius: 6px;
    }
  }

  &__columns {
    display: grid;
    grid-row-gap: 2rem;
    align-items: center;
    grid-column-gap: 4rem;
    grid-template-columns: repeat(1, 1fr);
  }

  &__column {
    &--right {
      display: none;
    }

    &--wide {
      text-align: center;
    }

    .button:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__circle {
    width: 100%;
    padding: 2.5rem;
    overflow: hidden;
    border-radius: 100%;
    background: $color-white;
    border: 5px solid $color-dark-green;
  }

  &--gray {
    color: $color-dark-green;
    background-vegetables-gray();

    h1, h2, h3, h4, h5 {
      color: $color-dark-green;
    }
  }

  &--green {
    color: $color-white;
    background-vegetables-green();

    h1, h2, h3, h4, h5 {
      color: $color-white;
    }
  }

  :last-child {
    margin-bottom: 0;
  }
}

@media all and (min-width: $breakpoint-m) {
  .content {
    &__column {
      .button:not(:last-child) {
        margin-bottom: 0;
        margin-right: 1rem;
      }
    }
  }
}

@media all and (min-width: $breakpoint-l) {
  .content {
    &__header {
      margin-bottom: 3rem;
    }

    &__video {
      //padding: 0 20%;
      margin-bottom: 4rem;
    }

    &__columns {
      grid-row-gap: 4rem;
      grid-template-columns: 2fr 1fr;
    }

    &__column {
      &--right {
        display: block;
      }

      &--wide {
        grid-column: span 2;
      }
    }
  }
}