.section {
  width: 100%;
  margin: 0 auto;

  &__inner {
    padding-top: $margin-vertical-small;
    padding-bottom: $margin-vertical-small;

    &.listing__inner {
      padding-top: $margin-horizontal-small;
      padding-bottom: $margin-horizontal-small;
    }
  }

  &__header {
    margin-bottom: 0;
    text-align: center;
  }
}

@media all and (min-width: 640px) {
  .section {
    &__inner {
      padding-top: $margin-vertical-large;
      padding-bottom: $margin-vertical-large;
    }
  }
}

@media all and (min-width: $breakpoint-xl) {
  .section {
    &__header {
      margin-bottom: 2.5rem;
    }
  }
}

@media all and (min-width: $breakpoint-xxl) {
  .section {
    &__inner {
      padding-top: $margin-vertical-large * 2;
      padding-bottom: $margin-vertical-large * 2;
    }
  }
}