.hero {
  width: 100%;
  height: 34rem;
  position: relative;
  background-size: cover;
  background-repeat: none;
  background-position: center;

  &--video {
    height: auto;
  }

  &--video &__video {
    width: 100%;
  }

  &--video &__video iframe {
    width: 100%;
  }

  &__background {
    overflow: hidden;
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    z-index: -1;

    background-size: cover;
    background-repeat: none;
    background-position: center;
    background-color: $color-dark-green;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__inner {
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
  }

  &:not(&--video):before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    background: rgba($color-dark-green, .8);

    position: absolute;
    top: 0; left: 0;
    z-index: 0;
  }

  &__content {
    text-align: center;
    color: $color-white;

    h1, h2, h3, h4, h5,
    .h1, .h2, .h3, .h4, .h5 {
      color: $color-white;
    }

    position: relative;
    z-index: 1;

    :last-child {
      margin-bottom: 0;
    }
  }

  &--home &__content {
    font-weight: bold;
    letter-spacing: .24em;
    text-transform: uppercase;
    font-family: $font-montserrat;
  }
}

@media all and (min-width: $breakpoint-xl) {
  .hero {
    height: calc(100vh - 7.75rem);

    &--video {
      height: auto;
    }
  }
}

@media all and (min-width: $breakpoint-xxl) {
  .hero {
    &--home &__title {
      font-size: 5rem;
      margin-bottom: 3rem;
    }

    &--home &__content {
      p {
        margin-bottom: 3rem;
      }
    }
  }
}
