.button {
  margin: 0 0;
  outline: none;
  appearance: none;
  padding: 1rem 2rem;
  display: inline-block;
  border-radius: .375rem;
  font-family: $font-montserrat;
  border: 1px solid $color-white;

  cursor: pointer;
  font-weight: 600;
  font-size: .875rem;
  line-height: .875rem;
  text-decoration: none;
  letter-spacing: .125rem;
  text-transform: uppercase;
  transition: all 200ms ease-in-out;

  background: $color-white;
  color: $color-dark-green;

  .sprite {
    fill: $color-dark-green;
  }

  &--green {
    background: $color-dark-green;
    border-color: $color-dark-green;
    color: $color-white;

    .sprite {
      fill: $color-white;
    }
  }

  &--black {
    background: $color-black;
    border-color: $color-black;
    color: $color-white;

    .sprite {
      fill: $color-white;
    }
  }

  &--small {
    font-size: .75rem;
    padding: .375rem .5rem;
  }

  &--icon {
    width: 1.75rem;
    height: 1.75rem;
    padding: .3125rem;
    border-radius: 100%;

    .sprite {
      width: 1rem;
      height: 1rem;
    }
  }

  &:hover {
    opacity: .8;
  }

  &--disabled,
  &[disabled] {
    opacity: .5;

    &:hover {
      opacity: .5;
    }
  }
}