.dataTables_info {
  display: none;
}

.dataTables_wrapper .dataTables_filter {
  float: none;
  text-align: left;
  margin-bottom: 1rem;

  label {
    color: $color-white;
  }

  input[type="search"] {
    width: 100%;
    border: none;
    outline: none;
    appearance: none;
    margin .5rem 0 0 0;
    border-radius: .25rem;

    padding: .75rem .75rem;
    background: $color-white;

    font-weight: 600;
    font-size: .875rem;
    color: $color-dark-gray;
    font-family: $font-sourcecode;

    &::placeholder {
      color: ($color-light-gray - 60);
      text-transform: uppercase;
    }
  }
}

table.dataTable thead th,
table.dataTable thead td,
table.dataTable tbody th,
table.dataTable tbody td {
  padding: 1rem 1rem;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  background-size: 30px 19px;
}

table.dataTable thead .sorting_asc {
  background-image: url('../img/sort_asc.png');
}

table.dataTable thead .sorting_desc {
  background-image: url('../img/sort_desc.png');
}

table.dataTable thead .sorting {
  background-image: url('../img/sort_both.png');
}

.dataTables_wrapper {
  .dataTables_paginate.paging_simple {
    width: 100%;
    display: flex;
    padding-top: 1rem;
    align-items: center;
    justify-content: space-between;

    .paginate_button {
      @extends .button;
    }

    .paginate_button {
      &, &:hover, &:active, &:focus {
        cursor: pointer;
        padding: .75rem 1.25rem;
        background: $color-white;
        border-color: $color-white;
        color: $color-dark-green !important;
      }

      &:hover {
        opacity: .8;
      }

      &.disabled {
        opacity: .5;
        cursor: not-allowed;
      }
    }
  }
}

table.dataTable thead th,
table.dataTable thead td,
table.dataTable.no-footer {
  border-bottom: 0;
}

table.dataTable thead th,
table.dataTable thead td {
  border-bottom: 2px solid $color-light-gray - 10;
}

.toplist {
  width: 100% !important;
  overflow: hidden;
  border-radius: .375rem;

  th {
    outline: none;
    text-align: left;
    color: $color-dark-green;
    background: $color-light-gray;

    &:nth-child(1) {
      text-align: center;
    }

    &:nth-child(3),
    &:nth-child(4) {
      display: none;
    }
  }

  td {
    color: $color-dark-green;

    &:nth-child(1) {
      text-align: center;

      span {
        width: 1.5rem;
        height: 1.5rem;
        text-align: center;
        line-height: 1.5rem;
        border-radius: 100%;
        color: $color-white;
        display: inline-block;
        background: $color-orange;
      }
    }

    &:nth-child(3),
    &:nth-child(4) {
      display: none;
    }
  }

  tr:nth-child(even) {
    background: $color-light-gray;
  }
}

@media all and (min-width: $breakpoint-m) {
  .toplist {
    th {
      &:nth-child(3),
      &:nth-child(4) {
        display: table-cell;
      }
    }

    td {
      &:nth-child(3),
      &:nth-child(4) {
        display: table-cell;
      }
    }
  }
}