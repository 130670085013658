.multicol {
  position: relative;

  &--gray {
    color: $color-dark-green;
    background-vegetables-gray();

    h1, h2, h3, h4, h5 {
      color: $color-dark-green;
    }
  }

  &--green {
    color: $color-white;
    background-vegetables-green();

    h1, h2, h3, h4, h5 {
      color: $color-white;
    }
  }

  &__columns {
    display: grid;
    text-align: center;
    align-items: stretch;
    grid-template-columns: repeat(1, 1fr);
  }

  &__column {
    display: flex;
    align-items: flex-start;

    h2 {
      margin-bottom: 1.5rem;
    }

    img {
      width: 40%;
      height: auto;
      max-width: 14.6875rem;
      display: inline-block;
    }
  }

  :last-child {
    margin-bottom: 0;
  }
}

@media all and (min-width: $breakpoint-l) {
  .multicol {
    &__columns {
      grid-template-columns: repeat(2, 1fr);
    }

    &__inner {
      margin: 0 0;
      max-width: 38.6875rem;
    }
  }
}

@media all and (min-width: $breakpoint-xxl) {
  .multicol {
    &__column:first-child {
      justify-content: flex-end;
    }

    &__column:first-child &__inner {
      padding-left: 0;
    }

    &__column:last-child {
      justify-content: flex-start;
    }

    &__column:last-child &__inner {
      padding-right: 0;
    }
  }
}